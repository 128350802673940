import React from "react";
import * as styles from "./Input.module.scss";

const Input = ({
  label,
  type,
  required = false,
  rows = 4,
  onChange,
  value,
  placeholder,
  field,
  error,
}) => {
  const onChangeValue = (value) => {
    onChange(value, field);
  };

  const renderInput = () => {
    switch (type) {
      case "text":
        return(
        <>
          <input
            onChange={(e) => onChangeValue(e.target.value)}
            type="text"
            name={label}
            placeholder={placeholder}
            required={required}
            value={value}
            
          />
          {error && <span style={{color:'red'}}>{error}</span>}
        </>);
      case "email":
        return (
          <>
          <input
            onChange={(e) => onChangeValue(e.target.value)}
            type="email"
            name={label}
            placeholder={placeholder}
            required={required}
            value={value}
          />
          {error && <span  style={{color:'red'}}>{error}</span>}
          </>
        );
      case "number":
        return (
          <input
            onChange={(e) => onChangeValue(e.target.value)}
            type="number"
            name={label}
            placeholder={placeholder}
            required={required}
            value={value}
          />
        );
      case "textarea":
        return (
          <textarea
            onChange={(e) => onChangeValue(e.target.value)}
            rows={rows}
            name={label}
            placeholder={placeholder}
            required={required}
            value={value}
          />
        );
      case "file":
        return (
          <input
            onChange={(e) => onChangeValue(e.target.files[0])}
            type="file"
            name={label}
            required={required}
          />
        );
    }
  };

  return (
    <label className={styles.input}>
      <span>
        {label}
        {required && " *"}
      </span>
      {renderInput(onChange)}
    </label>
  );
};

export default Input;
