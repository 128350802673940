import React from 'react';
import * as styles from './styles.module.scss';
function GetBanner(){
    return(
        <div className={styles.getbanner}>
            <h1 data-aos="zoom-in" data-aos-delay="100">
                Let's 
                <span> work </span>
                together
            </h1>
        </div>
    )
};
export default GetBanner ;