import { Link } from "gatsby";
import React from "react";
import * as styles from "./StyledButton.module.scss";
import {FaChevronRight } from 'react-icons/fa'
const StyledButton = ({ link, extraClass, icon, title, email = false, onClick }) => {
  return (
    <>
      {!email ? (
        <Link className={styles.styledLink} to={link}>
          <div className={`${styles[extraClass]}`}>
            <div> {title} </div>
            {icon ? <FaChevronRight /> : null}
          </div>
        </Link>
      ) : (
        <a onClick={onClick} className={styles.styledLink} target="_blank" href={link}>
          <div className={`${styles[extraClass]}`}>
            <div> {title} </div>
            {icon ?  <FaChevronRight /> : null}
          </div>
        </a>
      )}
    </>
  );
};
export default StyledButton;
