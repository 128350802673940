// extracted by mini-css-extract-plugin
export var bgShadow = "StyledButton-module--bgShadow--d2Zg+";
export var bigBtn = "StyledButton-module--bigBtn--YbnIb";
export var blackTxt = "StyledButton-module--blackTxt--AqNP7";
export var bluebtn = "StyledButton-module--bluebtn--ilrQZ";
export var bluebtn2 = "StyledButton-module--bluebtn2--2gf6q";
export var borderbtn = "StyledButton-module--borderbtn--QMItV";
export var graybtn = "StyledButton-module--graybtn--9vO+T";
export var nobgLink = "StyledButton-module--nobgLink--26c6X";
export var orangeGray = "StyledButton-module--orangeGray--g2suw";
export var slide1 = "StyledButton-module--slide1--sB18E";
export var styledLink = "StyledButton-module--styled-link--qo+M4";
export var whiteBtn = "StyledButton-module--white-btn--u9zOD";
export var whiteBtn1 = "StyledButton-module--white-btn1--hTwLc";
export var whiteLink = "StyledButton-module--whiteLink--gXRP9";