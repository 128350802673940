import React,{useEffect} from 'react';
import Footer from "../components/atoms/footer";
import Header from "../components/atoms/header";
import GetBanner from '../components/molecules/getStarted/banner';
import GetForm from '../components/molecules/getStarted/Form';
import AOS from "aos";
import "aos/dist/aos.css";
function Index(){
    useEffect(() => {
        AOS.init({
          disable: "mobile",
          duration: 1500,
        });
      }, []);
    return(
        <>
        <Header />
        <GetBanner />
        <GetForm/>
        <Footer />
        </>
    )
};
export default Index;